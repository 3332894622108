<template>
  <div class="csn-rc">
    <div class="casino-intro csn-slider-container">
      <SlideShow :list="REWARD_SLIDE_LIST" />
    </div>
    <Loader v-if="isPending" />
    <RewardBreadcrumbs
      v-else
      :list="categoryList"
      @select-category="selectCategory"
    />
    <div v-if="!isPending" class="csn-rc-card-list">
      <RewardCard
        v-for="(item, index) in filteredCardList"
        :key="index"
        :card="item"
      />
    </div>

    <div class="csn-rc-info">
      <span>{{ t('text_1') }}</span>
      <span>{{ t('text_2') }}</span>
      <span>{{ t('text_3') }}</span>
      <span>{{ t('text_4') }}</span>
      <span>{{ t('text_5') }}</span>
      <span>{{ t('text_6') }}</span>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { RewardApi } from '@/api'
import {
  REWARD_CARNIVAL,
  Module,
  REWARD_SLIDE_LIST,
  ResponseState,
  REGEX_PRE_FILE_URI,
  REGEX_PRE_LAST_SLASH,
  EMPTY_STRING,
  EMPTY_ARRAY,
  URL,
  NAME,
  EMPTY_OBJECT,
} from '@/constants'
import { indexBy, pipe, map, isArray } from '@/helpers'

const NO_FILTER = 'no-filter'
const categoryImageList = require.context(
  '../../assets/images/icons/rewards',
  true,
  /\.(png|jpg|jpeg|svg)$/,
)
const caregoryFileNameDictionary = pipe(
  (images) => images.keys(),
  map((image) => ({
    [URL]: categoryImageList(image),
    [NAME]: image.replace(REGEX_PRE_FILE_URI, EMPTY_STRING).toLowerCase(),
  })),
  indexBy(NAME),
)(categoryImageList)

export default {
  name: REWARD_CARNIVAL,
  components: {
    SlideShow: () => import('@/components/SlideShow'),
    RewardBreadcrumbs: () => import('./components/RewardBreadcrumbs'),
    RewardCard: () => import('./components/RewardCard'),
    Loader: () => import('@/components/Loader'),
  },
  data: () => ({
    cardList: EMPTY_ARRAY,
    categoryList: EMPTY_ARRAY,
    isPending: false,
    selectedCategory: null,
  }),
  computed: {
    ...mapState(Module.SLIDE, [REWARD_SLIDE_LIST]),
    t() {
      return this.$createComponentTranslator(REWARD_CARNIVAL)
    },
    filteredCardList() {
      const { type, name } = this.selectedCategory || EMPTY_OBJECT
      const needsFilter = type !== NO_FILTER

      return needsFilter
        ? this.cardList.filter(({ categories }) => categories === name)
        : this.cardList
    },
  },
  methods: {
    selectCategory(category) {
      this.selectedCategory = category
    },
  },
  async created() {
    try {
      this.isPending = true

      const [rewardList, rewardCategories] = await Promise.all([
        RewardApi.getRewardList(),
        RewardApi.getRewardCategories(),
      ])
      const isValidData =
        rewardList.state === ResponseState.OK &&
        isArray(rewardList.data) &&
        rewardCategories.state === ResponseState.OK &&
        isArray(rewardCategories.data)

      if (isValidData) {
        this.cardList = rewardList.data

        this.categoryList = map((item) => {
          const imageName = item.imageLink.replace(
            REGEX_PRE_LAST_SLASH,
            EMPTY_STRING,
          )

          return {
            ...item,
            imageLink: caregoryFileNameDictionary[imageName][URL],
          }
        })(rewardCategories.data)
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.isPending = false
    }
  },
}
</script>
